<template class="content">
  <img class="bg-image" src="./assets/bg.png" />
  <div class="info-group">
    <div class="title">欢迎光临{{ webName }}</div>
    <div class="info">网站建设中</div>
  </div>
  <div class="icp-info">{{ icpNo }}</div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        webName: '重庆祥安实业有限公司',
        icpNo: '渝ICP备2023008920号-1',
      };
    },
  };
</script>

<style>
  html,
  body {
    height: 100%;
    margin: 0;
  }
  #app {
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
  }

  .info-group {
    display: flex;
    flex-direction: column;
    -webkit-text-stroke: 2px #333;
  }

  .title {
    font-size: 70px;
    display: flex;
    justify-content: center;
    line-height: 250px;
    -webkit-text-stroke: 2px #333;
  }

  .info {
    font-size: 50px;
    display: flex;
    justify-content: center;
  }

  .icp-info {
    font-size: 20px;
    display: flex;
    justify-content: center;
    -webkit-text-stroke: 1px #333;
  }

  .content {
    position: relative;
  }
  .bg-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
  }
</style>
